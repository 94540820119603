<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <!-- <el-form-item label="状态:">
              <el-select
                v-model="page_status"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in status_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="begin_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="end_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              >
              </el-date-picker>
            </el-form-item>-->
            <el-form-item label="订单号:">
              <el-input
                v-model="order_num"
                clearable
                size="medium"
                placeholder="请输入订单号"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品名称:">
              <el-input
                v-model="product_name"
                clearable
                size="medium"
                placeholder="请输入产品名称"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤</el-button
            >
            <!-- <el-button
                class="top-button"
                size="medium"
                type="primary"
                round
                @click="dialogGenerateAnti = true"
                >添加
            </el-button>-->
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="订单号" align="center" width="150">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.order_num }}</p>
            </template>
          </el-table-column>
          <el-table-column label="拉别" align="center" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.line_name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="报关ID" align="center" width="150">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.bg_id }}</p>
            </template>
          </el-table-column>
          <el-table-column label="产品名称" align="center" width="150">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.product_name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="物料编码" align="center" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.product_sku }}</p>
            </template>
          </el-table-column>
          <el-table-column label="实际上传数量" align="center" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.actual_count }}</p>
            </template>
          </el-table-column>
          <el-table-column label="绑定成功数量" align="center" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.success_count }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作人" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.username }}</p>
            </template>
          </el-table-column>
          <el-table-column label="订单时间" align="center" width="185">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.order_date) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="上传时间" align="center" width="185">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.created_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="80">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="
                  status_list[scope.row.upload_status]
                    ? status_list[scope.row.upload_status].tag
                    : ''
                "
                effect="dark"
              >
                {{
                  status_list[scope.row.upload_status]
                    ? status_list[scope.row.upload_status].name
                    : "-"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="checkModelDetail(scope.row)"
                type="text"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <div class="export_file">
          <el-button type="primary" @click="exportFile">导出excel</el-button>
        </div>
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CodeUploadListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      order_num: "",
      product_name: "",
      model_list: [],
      begin_time: "",
      end_time: "",
      status_list: [
        {
          id: 0,
          name: "所有",
          tag: "primary",
        },
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
      status_select_list: [
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
      multipleSelection: [],
    };
  },
  created() {
    // if (this.$route.query.batch_no) {
    //   this.batch_no = this.$route.query.batch_no;
    // }
    // if (this.$route.query.query_code) {
    //   this.query_code = this.$route.query.query_code;
    // }
    this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;
      let params = {
        // key: _me.search_key,
        // status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
        order_num: _me.order_num,
        product_name: _me.product_name,
        // begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        // end_time: _me.$common.formatTimestampLongZone(_me.end_time),
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/external/upload/log/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    checkModelDetail(model) {
      let path =
        model && model.id
          ? "code-upload-detail?id=" + model.id
          : "code-upload-detail";
      this.$router.push(path);
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.id;
      });
    },
    exportFile() {
      let _me = this;
      if (_me.multipleSelection.length === 0) {
        _me.$message({
          message: "勾选要导出的文件",
          type: "error",
        });
        return;
      }
      let params = {
        upload_log_id_list: _me.multipleSelection,
      };
      _me.loading = true;
      _me.$common
        .httpDownload("/api/anti/fake/admin/external/upload/export", params)
        .then((res) => {
          _me.loading = false;
          this.$common.downloadFile(
            res,
            "application/vnd.ms-excel;charset=utf-8",
            "验真码上传记录"
          );
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });

      // _me.$common
      //   .httpPostBlob(
      //     "/api/verification_code/admin/external/upload/export",
      //     params
      //   )
      //   .then((res) => {
      //     let content_disposition = decodeURI(
      //       res.headers["content-disposition"]
      //     ); //解码后获取文件名字
      //     content_disposition = content_disposition.substring(
      //       content_disposition.indexOf("utf-8") + 7
      //     );
      //     res = res.data;
      //     _me.loading = false;
      //     if (res && res.size > 0) {
      //       const blob = new Blob([res]);
      //       // const fileName = "刮刮码.xlsx"; //下载文件名称
      //       const elink = document.createElement("a");
      //       elink.download = content_disposition;
      //       elink.style.display = "none";
      //       elink.href = URL.createObjectURL(blob);
      //       document.body.appendChild(elink);
      //       elink.click();
      //       URL.revokeObjectURL(elink.href); // 释放URL 对象
      //     } else {
      //       _me.$message({
      //         message: res.message || "获取数据失败",
      //         type: "error",
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     _me.loading = false;
      //     _me.$message({
      //       message: err || "获取数据失败",
      //       type: "error",
      //     });
      //   });
    },
  },
};
</script>

<style scoped>
.footer-info {
  height: 60px;
  text-align: right;
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
