<template>
  <el-container>
    <el-header class="banner-head" height="60px">
      <div class="head-info">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="查询码:">
            <el-input v-model="query_code" clearable size="medium" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item label="IP:">
            <el-input v-model="ip" clearable size="medium" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item label="国家:">
            <el-select v-model="country" clearable placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in country_list"
                :key="item.id"
                :label="item.name"
                :value="item.tag"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绘图类型:">
            <el-select
              v-model="mapConfig.mapType"
              clearable
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option v-for="item in mapTypeList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间:">
            <el-date-picker
              v-model="begin_time"
              type="datetime"
              placeholder="选择日期时间"
              default-time="12:00:00"
              align="center"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:">
            <el-date-picker
              v-model="end_time"
              type="datetime"
              placeholder="选择日期时间"
              default-time="12:00:00"
              align="center"
            ></el-date-picker>
          </el-form-item>
          <el-button class="top-button" size="medium" type="primary" round @click="getModelList">过滤</el-button>
        </el-form>
      </div>
    </el-header>
    <WorldMapView :mapConfig="mapConfig" v-if="isReady"></WorldMapView>
  </el-container>
</template>
<script>
import WorldMapView from "@/components/ChoroplethWorldMap.vue";

import world50 from "./countries-50m.json";
import state10m from "./states-albers-10m.json";

export default {
  name: "CodeRecordMapView",
  components: {
    WorldMapView
  },
  data() {
    return {
      search_key: "",
      query_code: "",
      ip: "",
      country: "",
      begin_time: "",
      end_time: "",
      mapConfig: {
        // mapType: 'canvas',
        mapType: "svg",
        mapWorldJson: world50,
        // mapUsaJson: state10m,
        mapDataArr: null,
        mapWidth: 1000
      },
      model_list: [],
      country_list: [],
      mapTypeList: ["svg", "canvas"],
      isReady: false
    };
  },
  created() {
    this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;
      let params = {
        begin_time: "",
        end_time: ""
      };
      _me.$common
        .httpPost("/api/anti/fake/admin/query/log/find/map", params)
        .then(res => {
          if (res.code === "1000000") {
            _me.mapConfig.mapDataArr = res.data;
            _me.isReady = true;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    }
  }
};
</script>
<style scoped>
.mapContainer {
  margin-top: 50px;
}
</style>
