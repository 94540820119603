<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="状态:">
              <el-select
                v-model="page_status"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in status_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="begin_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="end_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="批次号:">
              <el-input
                v-model="batch_no"
                clearable
                size="medium"
                placeholder="请输入批次号"
              ></el-input>
            </el-form-item>
            <el-form-item label="查询码:">
              <el-input
                v-model="query_code"
                clearable
                size="medium"
                placeholder="请输入查询码"
              ></el-input>
            </el-form-item>
            <el-form-item label="查询次数:">
              <el-input
                v-model="query_times"
                clearable
                size="medium"
                placeholder="请输入查询次数"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤
            </el-button>
            <!-- <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="dialogGenerateAnti = true"
              >添加
            </el-button> -->
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="查询码" align="center">
            <template slot-scope="scope">
              <a
                :href="'/code-record-list?query_code=' + scope.row.query_code"
                class="jump_button"
              >
                {{ scope.row.query_code }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="查询次数" align="center" width="150">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.query_times }}</p>
            </template>
          </el-table-column>
          <el-table-column label="验真码批次号" align="center" width="150">
            <template slot-scope="scope">
              <a
                :href="'/code-batch-list?batch_no=' + scope.row.batch_no"
                class="jump_button"
              >
                {{ scope.row.batch_no }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="验真码批次序列" align="center" width="150">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.batch_serial }}</p>
            </template>
          </el-table-column>
          <el-table-column label="验证码" align="center" width="150">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.verify_code }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="150">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="
                  status_list[scope.row.status]
                    ? status_list[scope.row.status].tag
                    : ''
                "
                effect="dark"
                >{{
                  status_list[scope.row.status]
                    ? status_list[scope.row.status].name
                    : "-"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="生成时间" align="center" width="200">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.created_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="checkModelDetail(scope.row)"
                type="text"
                >详情
              </el-button>
              <el-button
                style="color: #03aef9"
                @click="updateAntiHandle(scope.row)"
                type="text"
              >
                更新验真码
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>

    <!-- 更新验真码 -->
    <el-dialog title="更新验真码" :visible.sync="dialogUpdateAnti">
      <el-form
        :model="update_form"
        label-width="220px"
        style="text-align: left"
      >
        <el-form-item label="状态:">
          <el-select
            v-model="update_form.status"
            placeholder="请选择"
            loading-text="加载中..."
          >
            <el-option
              v-for="item in status_select_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUpdateAnti = false">取 消</el-button>
        <el-button type="primary" @click="updateAnti">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CodeListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      batch_no: "",
      query_code: "",
      query_times: "",
      model_list: [],
      begin_time: "",
      end_time: "",
      dialogUpdateAnti: false,
      update_form: {
        id: "",
        status: "",
      },
      status_list: [
        {
          id: 0,
          name: "所有",
          tag: "primary",
        },
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
      status_select_list: [
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
    };
  },
  created() {
    if (this.$route.query.batch_no) {
      this.batch_no = this.$route.query.batch_no;
    }
    if (this.$route.query.query_code) {
      this.query_code = this.$route.query.query_code;
    }
    this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;
      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "-created_at,-batch_serial",
        batch_no: _me.batch_no,
        query_code: _me.query_code,
        query_times: _me.query_times,
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time),
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    updateAntiHandle(item) {
      this.dialogUpdateAnti = true;
      this.update_form.id = item.id;
      this.update_form.status = item.status;
    },
    updateAnti() {
      let _me = this;
      let params = {
        id: _me.update_form.id,
        status: _me.update_form.status,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("更新状态成功");
            _me.dialogUpdateAnti = false;
            _me.getModelList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    checkModelDetail(model) {
      let path =
        model && model.id ? "code-detail?id=" + model.id : "code-detail";
      this.$router.push(path);
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
  },
};
</script>

<style scoped></style>
