<template>
  <div class="mapContainer">
    <canvas v-if="config.mapType === 'canvas'"></canvas>
    <div id="chart" v-else></div>
  </div>
</template>
<script>
import * as d3 from "d3";
import * as topojson from "topojson-client"

export default {
  name: "ChoroplethWorldMap",
  props: {
    mapConfig: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      config: {
        mapType: '',
        mapDataArr: [],
        mapWorldJson: {},
        mapUsaJson: null,
        width: 0,
        // height: undefined,
        projection: null
      }
    };
  },
  mounted() {
    this.getMapConfig();
  },
  methods: {
    getMapConfig() {
      let _me = this;
      let mc = _me.mapConfig;
      if (!mc) return
      if (!mc.mapWorldJson && !mc.mapUsaJson) return

      _me.config.mapDataArr = mc.mapDataArr;

      let mapJson;
      //feature collection
      let featureCollection;
      //stroking borders
      let strokingBorders;

      let EqualEarthProjection = d3.geoEqualEarth()
      let MercatorProjection = d3.geoMercator()

      if (mc.mapWorldJson) {
        mapJson = mc.mapWorldJson;
        _me.config.width = mc.mapWidth && mc.mapWidth > 0 ? mc.mapWidth : 1000;
        // _me.config.height = mc.mapHeight && mc.mapHeight > 0 ? mc.mapHeight : 1000;

        if (!mc.projection) {
          mc.projection = EqualEarthProjection;
          // mc.projection = MercatorProjection;
        }

        //feature collection
        featureCollection = topojson.feature(mapJson, mapJson.objects.countries);
        //stroking borders
        strokingBorders = topojson.mesh(mapJson, mapJson.objects.countries, (a, b) => a !== b);
      } else {
        mapJson = mc.mapUsaJson;
        _me.config.width = mc.mapWidth && mc.mapWidth > 0 ? mc.mapWidth : 975;
        // _me.config.height = mc.mapHeight && mc.mapHeight > 0 ? mc.mapHeight : 610;
        _me.config.height = (_me.config.width / 975) * 610;

        //feature collection
        featureCollection = topojson.feature(mapJson, mapJson.objects.states);
        //stroking borders
        strokingBorders = topojson.mesh(mapJson, mapJson.objects.states, (a, b) => a !== b);
      }

      _me.config.projection = mc.projection
      if (mc.mapType && mc.mapType === 'canvas') {
        _me.config.mapType = 'canvas';
        setTimeout(function () {
          _me.initCanvasChoroplethMap(featureCollection, strokingBorders);
        }, 0)
      } else {
        _me.config.mapType = 'svg';
        setTimeout(function () {
          _me.initSVGChoroplethMap(featureCollection, strokingBorders);
        }, 0)
      }
    },
    initCanvasChoroplethMap(featureCollection, strokingBorders) {
      let c = this.config;
      Choropleth(c.mapDataArr ? c.mapDataArr : [], {
        id: d => d.id, // country name, e.g. Zimbabwe
        value: d => d.value,
        range: d3.interpolateYlGnBu,
        features: featureCollection,
        borders: strokingBorders,
        width: c.width,
        height: c.height,
        projection: c.projection,
      });

      function Choropleth(data, {
        id = d => d.id, // given d in data, returns the feature id
        value = () => undefined, // given d in data, returns the quantitative value
        title, // given a feature f and possibly a datum d, returns the hover text
        format, // optional format specifier for the title
        scale = d3.scaleSequential, // type of color scale
        domain, // [min, max] values; input of color scale
        range = d3.interpolateBlues, // output of color scale
        width = 640, // outer width, in pixels
        height, // outer height, in pixels
        projection, // a D3 projection; null for pre-projected geometry
        features, // a GeoJSON feature collection
        featureId = d => d.id, // given a feature, returns its id
        borders, // a GeoJSON object for stroking borders
        outline = projection && projection.rotate ? {type: "Sphere"} : null, // a GeoJSON object for the background
        unknown = "#ccc", // fill color for missing data
        fill = "white", // fill color for outline
        stroke = "white", // stroke color for borders
        strokeLinecap = "round", // stroke line cap for borders
        strokeLinejoin = "round", // stroke line join for borders
        strokeWidth, // stroke width for borders
        strokeOpacity, // stroke opacity for borders
      } = {}) {
        // Compute values.
        const N = d3.map(data, id);
        const V = d3.map(data, value).map(d => d == null ? NaN : +d);
        const Im = new d3.InternMap(N.map((id, i) => [id, i]));
        const If = d3.map(features.features, featureId);

        // Compute default domains.
        if (domain === undefined) domain = d3.extent(V);

        // Construct scales.
        const color = scale(domain, range);
        if (color.unknown && unknown !== undefined) color.unknown(unknown);

        // Compute titles.
        if (title === undefined) {
          format = color.tickFormat(100, format);
          title = (f, i) => `${f.properties.name}\n${format(V[i])}`;
        } else if (title !== null) {
          const T = title;
          const O = d3.map(data, d => d);
          title = (f, i) => T(f, O[i]);
        }

        // Compute the default height. If an outline object is specified, scale the projection to fit
        // the width, and then compute the corresponding height.
        if (height === undefined) {
          if (outline === undefined) {
            height = 400;
          } else {
            const [[x0, y0], [x1, y1]] = d3.geoPath(projection.fitWidth(width, outline)).bounds(outline);
            const dy = Math.ceil(y1 - y0), l = Math.min(Math.ceil(x1 - x0), dy);
            projection.scale(projection.scale() * (l - 1) / l).precision(0.2);
            height = dy;
          }
        }

        const canvas = d3.select("canvas").node();
        canvas.width = width
        canvas.height = height

        const context = canvas.getContext("2d");
        const path = d3.geoPath(c.projection, context);

        features.features.forEach((d, i) => {
          context.beginPath()
          path(d)
          context.fillStyle = color(V[Im.get(If[i])])
          context.fill()
        })

        context.restore();
        context.beginPath();
        path(borders);
        context.strokeStyle = stroke;
        context.stroke();
        context.beginPath();
        path(outline);
        context.strokeStyle = stroke;
        context.stroke();
      }
    },
    initSVGChoroplethMap(featureCollection, strokingBorders) {
      let c = this.config;

      Choropleth(c.mapDataArr ? c.mapDataArr : [], {
        id: d => d.id, // country name, e.g. Zimbabwe
        value: d => d.value,
        scale: d3.scaleQuantize,
        domain: [0, 10],
        range: d3.schemeBlues[9],
        features: featureCollection,
        borders: strokingBorders,
        width: c.width,
        height: c.height,
        projection: c.projection,
      });

      function Choropleth(data, {
        id = d => d.id, // given d in data, returns the feature id
        value = () => undefined, // given d in data, returns the quantitative value
        title, // given a feature f and possibly a datum d, returns the hover text
        format, // optional format specifier for the title
        scale = d3.scaleSequential, // type of color scale
        domain, // [min, max] values; input of color scale
        range = d3.interpolateBlues, // output of color scale
        width = 640, // outer width, in pixels
        height, // outer height, in pixels
        projection, // a D3 projection; null for pre-projected geometry
        features, // a GeoJSON feature collection
        featureId = d => d.id, // given a feature, returns its id
        borders, // a GeoJSON object for stroking borders
        outline = projection && projection.rotate ? {type: "Sphere"} : null, // a GeoJSON object for the background
        unknown = "#ccc", // fill color for missing data
        fill = "white", // fill color for outline
        stroke = "white", // stroke color for borders
        strokeLinecap = "round", // stroke line cap for borders
        strokeLinejoin = "round", // stroke line join for borders
        strokeWidth, // stroke width for borders
        strokeOpacity, // stroke opacity for borders
      } = {}) {
        // Compute values.
        const N = d3.map(data, id);
        const V = d3.map(data, value).map(d => d == null ? NaN : +d);
        const Im = new d3.InternMap(N.map((id, i) => [id, i]));
        const If = d3.map(features.features, featureId);

        // Compute default domains.
        if (domain === undefined) domain = d3.extent(V);

        // Construct scales.
        const color = scale(domain, range);
        if (color.unknown && unknown !== undefined) color.unknown(unknown);

        // Compute titles.
        if (title === undefined) {
          format = color.tickFormat(100, format);
          title = (f, i) => `${f.properties.name}\n${format(V[i])}`;
        } else if (title !== null) {
          const T = title;
          const O = d3.map(data, d => d);
          title = (f, i) => T(f, O[i]);
        }

        // Compute the default height. If an outline object is specified, scale the projection to fit
        // the width, and then compute the corresponding height.
        if (height === undefined) {
          if (outline === undefined) {
            height = 400;
          } else {
            const [[x0, y0], [x1, y1]] = d3.geoPath(projection.fitWidth(width, outline)).bounds(outline);
            const dy = Math.ceil(y1 - y0), l = Math.min(Math.ceil(x1 - x0), dy);
            projection.scale(projection.scale() * (l - 1) / l).precision(0.2);
            height = dy;
          }
        }

        // Construct a path generator.
        const path = d3.geoPath(projection);

        document.getElementById("chart").style.width = width + 'px';
        document.getElementById("chart").style.height = height + 'px';

        const svg = d3.select("#chart").append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "width: 100%; height: auto; height: intrinsic;");

        if (outline != null) svg.append("path")
            .attr("fill", fill)
            .attr("stroke", "currentColor")
            .attr("d", path(outline));

        svg.append("g")
            .selectAll("path")
            .data(features.features)
            .join("path")
            .attr("fill", (d, i) => color(V[Im.get(If[i])]))
            .attr("d", path)
            .append("title")
            .text((d, i) => title(d, Im.get(If[i])));

        if (borders != null) svg.append("path")
            .attr("pointer-events", "none")
            .attr("fill", "none")
            .attr("stroke", stroke)
            .attr("stroke-linecap", strokeLinecap)
            .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-opacity", strokeOpacity)
            .attr("d", path(borders));
      }
    },
  },
};
</script>
<style scoped>
#chart {
  display: inline-block;
}
</style>
