<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="查询规则详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button
                type="primary"
                icon="el-icon-check"
                @click="saveModelOnclick"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="edit-form"
          :model="edit_model"
          ref="category_model"
          label-width="150px"
        >
          <el-form-item label="名称:" prop="name">
            <el-input v-model="edit_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="是否使用:">
            <el-switch
              v-model="edit_model.used"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <h2>状态列表</h2>
          <el-table
            :data="edit_model.status_list"
            style="width: 100%"
            :cell-style="$common.tableStyle"
            :header-cell-style="$common.tableStyle"
            border
            default-expand-all
          >
            <el-table-column label="查询状态" prop="name" width="100">
              <template slot-scope="scope">
                {{
                  status_select_list[scope.row.status - 1]
                    ? status_select_list[scope.row.status - 1].name
                    : "-"
                }}
              </template>
            </el-table-column>

            <el-table-column label="查询状态内容" prop="name">
              <template slot-scope="scope">
                {{ scope.row.message }}
              </template>
            </el-table-column>
            <el-table-column label="查询状态字体大小" prop="name" width="150">
              <template slot-scope="scope">
                {{ scope.row.font_size }}
              </template>
            </el-table-column>
            <el-table-column label="查询状态字体颜色" prop="name" width="150">
              <template slot-scope="scope">
                <el-color-picker
                  v-model="scope.row.font_color"
                ></el-color-picker>
              </template>
            </el-table-column>
            <el-table-column label="查询状态图片" prop="name" width="150">
              <template slot-scope="scope">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px"
                  :src="
                    scope.row.icon_url + $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[scope.row.icon_url]"
                  lazy
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope">
                <el-button @click="editStatusHandle(scope.row)" type="primary"
                  >编辑
                </el-button>
                <el-popover
                  placement="right"
                  width="400"
                  trigger="click"
                  style="margin-left: 10px"
                >
                  <div class="page_view">
                    <p>预览效果仅供参考，实际以网站查询验真码返回的页面为准</p>

                    <img
                      class="page_view_img"
                      :src="scope.row.icon_url"
                      alt=""
                    />
                    <div
                      :style="{
                        fontSize: scope.row.font_size + 'px',
                        color: scope.row.font_color,
                      }"
                    >
                      {{ scope.row.message }}
                    </div>
                  </div>
                  <el-button type="info" slot="reference"> 页面预览 </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>

          <h2>正常状态下次数列表</h2>
          <el-form-item style="text-align: right">
            <el-button type="primary" @click="addHandle"
              >添加次数规则</el-button
            >
          </el-form-item>
          <el-table
            :data="edit_model.query_times_list"
            style="width: 100%"
            :cell-style="$common.tableStyle"
            :header-cell-style="$common.tableStyle"
            border
            default-expand-all
          >
            <el-table-column label="查询次数" prop="name" width="150">
              <template slot-scope="scope">
                {{ scope.row.query_text }}
              </template>
            </el-table-column>

            <el-table-column label="查询次数内容" prop="name">
              <template slot-scope="scope">
                {{ scope.row.message }}
              </template>
            </el-table-column>
            <el-table-column label="查询次数字体大小" prop="name" width="150">
              <template slot-scope="scope">
                {{ scope.row.font_size }}
              </template>
            </el-table-column>
            <el-table-column label="查询次数字体颜色" prop="name" width="150">
              <template slot-scope="scope">
                <!-- {{ scope.row.font_color }} -->
                <el-color-picker
                  v-model="scope.row.font_color"
                ></el-color-picker>
              </template>
            </el-table-column>
            <el-table-column label="查询次数图片" prop="name" width="150">
              <template slot-scope="scope">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px"
                  :src="
                    scope.row.icon_url + $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[scope.row.icon_url]"
                  lazy
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <el-button @click="editTimesHandle(scope.row)" type="primary"
                  >编辑
                </el-button>
                <el-popover
                  placement="right"
                  width="400"
                  trigger="click"
                  style="margin: 0 10px"
                >
                  <div class="page_view">
                    <p>预览效果仅供参考，实际以网站查询验真码返回的页面为准</p>

                    <div>count:{{ scope.row.query_times }}</div>
                    <img
                      class="page_view_img"
                      :src="scope.row.icon_url"
                      alt=""
                    />
                    <div
                      :style="{
                        fontSize: scope.row.font_size + 'px',
                        color: scope.row.font_color,
                      }"
                    >
                      {{ scope.row.message }}
                    </div>
                  </div>
                  <el-button type="info" slot="reference"> 页面预览 </el-button>
                </el-popover>
                <el-button @click="deleteTimesHandle(scope.row)" type="danger"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-main>
    </el-container>

    <el-dialog title="状态详情" :visible.sync="dialogStatusFormVisible">
      <el-form :model="status_form" label-width="120px">
        <el-form-item label="状态内容">
          <el-input v-model="status_form.message" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态字体大小">
          <el-input
            v-model.number="status_form.font_size"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态字体颜色" style="text-align: left">
          <!-- <el-input v-model="status_form.font_color" autocomplete="off"></el-input> -->
          <el-color-picker v-model="status_form.font_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="状态状态图片">
          <el-input v-model="status_form.icon_url" clearable></el-input>
          <el-row>
            <el-col :span="12">
              <el-image
                style="width: 240px; height: 240px; margin-top: 10px"
                :src="
                  status_form.icon_url + $common.K_QINIU_IMAGE_SMALL_END_200
                "
                :preview-src-list="[status_form.icon_url]"
                lazy
                fit="contain"
              ></el-image>
            </el-col>
            <el-col :span="12">
              <dl>
                <dt class="edit-row-dt">
                  <el-button
                    size="mini"
                    type="warning"
                    @click="getImageByWeb('status_form')"
                  >
                    资源选择
                  </el-button>
                </dt>
              </dl>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogStatusFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStatusModelOnclick('status')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="次数详情" :visible.sync="dialogTimesFormVisible">
      <el-form :model="times_form" label-width="120px">
        <el-form-item label="次数内容">
          <el-input v-model="times_form.message" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="次数">
          <el-input
            v-model.number="times_form.query_times"
            autocomplete="off"
          ></el-input>
          <div class="gray" style="text-align: left">(提示: -1为∞)</div>
        </el-form-item>
        <el-form-item label="次数字体大小">
          <el-input
            v-model.number="times_form.font_size"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="次数字体颜色" style="text-align: left">
          <el-color-picker v-model="times_form.font_color"></el-color-picker>
        </el-form-item>
        <el-form-item label="次数图片">
          <el-input v-model="times_form.icon_url" clearable></el-input>
          <el-row>
            <el-col :span="12">
              <el-image
                style="width: 240px; height: 240px; margin-top: 10px"
                :src="times_form.icon_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[times_form.icon_url]"
                lazy
                fit="contain"
              ></el-image>
            </el-col>
            <el-col :span="12">
              <dl>
                <dt class="edit-row-dt">
                  <el-button
                    size="mini"
                    type="warning"
                    @click="getImageByWeb('times_form')"
                  >
                    资源选择
                  </el-button>
                </dt>
              </dl>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTimesFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveTimesModelOnclick"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <FileChooseDrawerModule ref="CodeFindRulesDetailFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../file/module/FileChooseDrawerModule";

export default {
  name: "CodeFindRulesDetailView",
  components: {
    FileChooseDrawerModule,
  },
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        status_list: [],
        query_times_list: [],
        used: false,
        name: "",
      },
      user_list: [],
      status_select_list: [
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
      //   query_times_list: [
      //     {
      //       query_times: 0,
      //       message: "",
      //       font_size: 0,
      //       font_color: "",
      //       icon_url: "",
      //     },
      //   ],
      status_list: [
        {
          status: 1,
          message: "正常",
          font_size: 0,
          font_color: "",
          icon_url: "",
        },
        {
          status: 2,
          message: "锁定",
          font_size: 0,
          font_color: "",
          icon_url: "",
        },
        {
          status: 3,
          message: "黑名单",
          font_size: 0,
          font_color: "",
          icon_url: "",
        },
      ],
      dialogStatusFormVisible: false,
      status_form: {
        status: 0,
        message: "",
        font_size: 0,
        font_color: "",
        icon_url: "",
      },
      dialogTimesFormVisible: false,
      times_form: {
        id: "",
        message: "",
        font_size: 24,
        font_color: "#000000",
        icon_url: "",
        query_times: 0,
      },
      is_status: false,
    };
  },
  created() {
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    } else {
      this.edit_model.status_list = this.status_list;
    }
  },
  methods: {
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/anti/fake/admin/style/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model_id = res.data.id;
            _me.edit_model.name = res.data.name;
            _me.edit_model.status_list = res.data.status_list;
            _me.edit_model.query_times_list = res.data.query_times_list;
            _me.edit_model.used = res.data.used;

            if (
              res.data.query_times_list &&
              res.data.query_times_list.length > 0
            ) {
              res.data.query_times_list.forEach((v, i) => {
                v.id = Number(i + 1);
                v.query_text = v.query_times;
                if (v.query_times == -1) {
                  v.query_text = "∞";
                }
              });
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    addHandle() {
      this.dialogTimesFormVisible = true;
    },
    editStatusHandle(item) {
      this.dialogStatusFormVisible = true;
      this.status_form.status = item.status;
      this.status_form.font_color = item.font_color;
      this.status_form.font_size = item.font_size;
      this.status_form.icon_url = item.icon_url;
      this.status_form.message = item.message;
    },
    editTimesHandle(item) {
      this.dialogTimesFormVisible = true;
      this.times_form.query_times = item.query_times;
      this.times_form.font_color = item.font_color;
      this.times_form.font_size = item.font_size;
      this.times_form.icon_url = item.icon_url;
      this.times_form.message = item.message;
      this.times_form.id = item.id;
    },
    deleteTimesHandle(item) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _me.edit_model.query_times_list.forEach((v, i) => {
            if (v.id == item.id) {
              _me.edit_model.query_times_list.splice(i, 1);
            }
          });
          _me.saveModelOnclick();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    saveStatusModelOnclick() {
      this.edit_model.status_list.forEach((v) => {
        if (v.status == this.status_form.status) {
          v.status = this.status_form.status;
          v.font_color = this.status_form.font_color;
          v.font_size = this.status_form.font_size;
          v.icon_url = this.status_form.icon_url;
          v.message = this.status_form.message;
        }
      });
      this.saveModelOnclick();
    },
    saveTimesModelOnclick() {
      if (this.times_form.id) {
        this.edit_model.query_times_list.forEach((v) => {
          if (v.id == this.times_form.id) {
            v.query_times = this.times_form.query_times;
            v.font_color = this.times_form.font_color;
            v.font_size = this.times_form.font_size;
            v.icon_url = this.times_form.icon_url;
            v.message = this.times_form.message;
          }
        });
      } else {
        this.edit_model.query_times_list.push(this.times_form);
      }
      this.saveModelOnclick();
    },
    saveModelOnclick() {
      if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
        this.updateOneModel();
        this.times_form.query_times = 0;
        this.times_form.font_color = "";
        this.times_form.font_size = 0;
        this.times_form.icon_url = "";
        this.times_form.message = "";
        this.times_form.id = "";
      } else {
        this.addNewModel();
        this.times_form.query_times = 0;
        this.times_form.font_color = "";
        this.times_form.font_size = 0;
        this.times_form.icon_url = "";
        this.times_form.message = "";
        this.times_form.id = "";
      }
    },
    addNewModel() {
      let _me = this;
      if (_me.edit_model.status_list && _me.edit_model.status_list.length > 0) {
        _me.edit_model.status_list.forEach((v) => {
          if (v.font_size == 0) {
            v.font_size = 24;
          }
          if (!v.font_color) {
            v.font_color = "#000000";
          }
        });
      }
      let params = {
        data: JSON.stringify(_me.edit_model),
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/style/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    updateOneModel() {
      let _me = this;
      if (_me.edit_model.status_list && _me.edit_model.status_list.length > 0) {
        _me.edit_model.status_list.forEach((v) => {
          if (v.font_size == 0) {
            v.font_size = 24;
          }
          if (!v.font_color) {
            v.font_color = "#000000";
          }
        });
      }
      let params = {
        id: _me.edit_model_id,
        data: JSON.stringify(_me.edit_model),
      };

      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/style/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.dialogStatusFormVisible = false;
            _me.dialogTimesFormVisible = false;
            _me.getEditModel();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getImageByWeb(form) {
      let _me = this;
      this.$refs.CodeFindRulesDetailFileChooseDrawerModule.showChooseDrawer(
        function (chooseFileList) {
          console.log("接收到选择的文件:", chooseFileList);
          if (chooseFileList.length > 0) {
            switch (form) {
              case "times_form":
                _me.times_form.icon_url = chooseFileList[0].file_url;
                break;
              case "status_form":
                _me.status_form.icon_url = chooseFileList[0].file_url;
                break;
              default:
                break;
            }
          }
        }
      );
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}

.edit-form {
  margin: 20px 10px 10px 0;
  text-align: left;
  /* min-width: 800px; */
  /* max-width: 800px; */
}

.page_view {
  text-align: center;
}

.page_view_img {
  width: 200px;
  margin: 50px 0;
}
</style>
